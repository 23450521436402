import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Form, Button, Table } from 'react-bootstrap';
import { transacaoService } from '../../services/transacaoService';
import { formatarDataParaInput, getDataMenosDias } from '../../Util/DataUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import CardsResumoFinanceiro from '../Financeiro/CardsResumoFinanceiro';

const RelatorioFinanceiro = () => {
  const today = new Date().toISOString().split('T')[0];

  const [dataInicio, setDataInicio] = useState(formatarDataParaInput(getDataMenosDias(30)));
  const [dataFim, setDataFim] = useState(today);
  const [resumoFinanceiro, setResumoFinanceiro] = useState(null);
  const [transacoes, setTransacoes] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const handleDataInicioChange = useCallback((event) => {
    setDataInicio(event.target.value);
  }, []);

  const handleDataFimChange = useCallback((event) => {
    setDataFim(event.target.value);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCarregando(true);

    const params = {
      dataInicial: dataInicio,
      dataFinal: dataFim,
      tamanhoPagina: 100
    };

    transacaoService.getResumo(params).then((resumo) => {
      setResumoFinanceiro(resumo);
    });

    transacaoService.get(params).then((transacoes) => {
      setTransacoes(transacoes.itens ?? []);
      setCarregando(false);
    }).catch(() => {
      setCarregando(false);
    });
  };

  const handlePrint = () => {
    const printContent = document.getElementById('tabelaRelatorioFinanceiro').outerHTML;
    const newWindow = window.open('', '', 'width=800,height=600');

    newWindow.document.write(`
      <html>
        <head>
          <title>Relatório Financeiro</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
        <h2>Relatório Financeiro</h2>
        <small>Gerado em ${new Date().toLocaleDateString() + ' às ' + new Date().toLocaleTimeString()}</small>
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="d-flex">
          <Col md={6}>
            <Form.Group controlId="formDataInicio" className="me-2">
              <Form.Label>Data Início</Form.Label>
              <Form.Control
                type="date"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDataFim">
              <Form.Label>Data Fim</Form.Label>
              <Form.Control
                type="date"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </Form.Group>
          </Col>

          <Col className='d-flex justify-content-end mt-2'>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faFileInvoice} /> Gerar Relatório
            </Button>

            {transacoes.length > 0 && (
              <Button className='ms-2' variant="secondary" onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} /> Imprimir
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            {carregando ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {resumoFinanceiro && (
                  <CardsResumoFinanceiro resumoFinanceiro={resumoFinanceiro} />
                )}
                <br />
                {transacoes.length > 0 ? (
                  <Table id="tabelaRelatorioFinanceiro" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Tipo</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transacoes.map((transacao, index) => (
                        <tr key={index}>
                          <td>{new Date(transacao.data).toLocaleDateString()}</td>
                          <td>{transacao.descricao}</td>
                          <td className={transacao.valor < 0 ? 'text-danger' : 'text-success'}>{transacao.tipo == 1 ? 'Saída' : 'Entrada'}</td>
                          <td className={transacao.valor < 0 ? 'text-danger' : 'text-success'}>R$ {transacao.valor}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <p style={{ fontWeight: 'bold' }}>Total de entradas: <span style={{color: 'rgb(2 184 117)'}}>R$ {resumoFinanceiro?.valorTotalEntradasDoPeriodo?.toFixed(2)?.replace('.', ',')}</span></p>
                          <p style={{ fontWeight: 'bold' }}>Total de saídas: <span style={{color: 'rgb(217 83 79)'}}>R$ {(resumoFinanceiro?.valorTotalSaidasDoPeriodo * -1)?.toFixed(2)?.replace('.', ',')}</span></p>
                          <p style={{ fontWeight: 'bold' }}>Saldo do período: <span style={{color: 'rgb(23 162 184)'}}>R$ {resumoFinanceiro?.saldoDoPeriodo?.toFixed(2)?.replace('.', ',')}</span></p>
                          <p style={{ fontWeight: 'bold' }}>Saldo atual: <span style={{color: 'rgb(69 130 236)'}}>R$ {resumoFinanceiro?.saldoAtual?.toFixed(2)?.replace('.', ',')}</span></p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <p className='text-center'>Nenhum dado disponível</p>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RelatorioFinanceiro;
