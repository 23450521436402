import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountService } from '../services/accountService';
import { toast } from 'react-toastify';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const isPageCadastro = window.location.pathname.includes('cadastro');
    const isPageEventos = window.location.pathname.includes('eventos');

    const login = async (userData) => {
        try {
            const user = await AccountService.login(userData);
            if (!user) return;
            const savedUser = { ...user, loginDate: new Date() };
            setUser(savedUser);
            localStorage.setItem('user', JSON.stringify(savedUser));
            navigate('/admin');
        } catch (error) {
            console.error('Erro durante o login:', error);
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        navigate('/login');
    };


    const loginAMaisDe7Dias = (loginDate) => {
        if (loginDate == null || loginDate == undefined || !loginDate) {
            return true;
        }

        const umDiaEmMs = 1000 * 60 * 60 * 24;
        const diferencaEmMs = Math.abs(new Date(loginDate) - new Date());
        const diferencaEmDias = Math.floor(diferencaEmMs / umDiaEmMs);
        return diferencaEmDias > 7;
    }

    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            const user = JSON.parse(savedUser);

            if (loginAMaisDe7Dias(user.loginDate)) {
                logout();
                toast.warning("Seção expirada! Faça o login novamente.");
            }

            setUser(user);
        } else {
            if (!isPageCadastro || !isPageEventos) return;
            navigate('/login');
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};
