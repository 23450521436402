import api from "./api/api";
import { createQueryParams } from "../Util/PageParamsUtil";
import { ApiErrorUtil } from "../Util/ApiErrorUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/evento", dataPost);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};

const get = async (pageParams) => {
    try {
        const { data } = await api.get(`/evento${createQueryParams(pageParams)}`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};

const getEmBreve = async () => {
    try {
        const { data } = await api.get(`/evento/get/em_breve`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};


const getAcontecidos = async () => {
    try {
        const { data } = await api.get(`/evento/get/acontecidos`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};

const getId = async (id) => {
    try {
        const { data } = await api.get(`/evento/id/${id}`);
        console.log(id)
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};

const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/evento?id=${id}`);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
};

export const eventoService = {
    post,
    get,
    deleteData,
    getId,
    getEmBreve,
    getAcontecidos
};
