import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { eventoService } from '../../services/eventoService';
import LogoImage from '../../resources/img/logo.jpg';
import api from '../../services/api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendar } from '@fortawesome/free-solid-svg-icons';

const Evento = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [evento, setEvento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    const fetchEvento = async () => {
      try {
        const data = await eventoService.getId(id);
        setEvento(data);
      } catch (error) {
        console.error("Erro ao buscar o evento:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvento();
  }, [id]);

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  if (loading) {
    return (
      <div className="container py-5">
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!evento) {
    return (
      <div className="container py-5">
        <div className="alert alert-warning" role="alert">
          Evento não encontrado.
        </div>
      </div>
    );
  }

  return (
    <div className="container py-2">
      {/* Cabeçalho */}
      <div className="row mb-4">
        <div className="col text-center">
          <img
            src={LogoImage}
            width="80"
            height="80"
            className="mb-3"
            alt="Logo"
          />
          <h1 className="text-danger">Crisma Raiz - Detalhes do evento</h1>
        </div>
      </div>

    <hr />

      {/* Informações do Evento */}
      <div className='text-center mb-4'>
        <h5 className="card-title">{evento.titulo}</h5>
        <p className="card-text">{evento.descricao}</p>
        <p className="card-text">
          <FontAwesomeIcon icon={faCalendar} />{' '}
          <small>
            Data: {new Date(evento.data).toLocaleDateString('pt-BR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </small>
        </p>
      </div>
      {/* Galeria de Imagens */}
      <div className="row mb-4">
        {evento.imagens && evento.imagens.length > 0 ? (
          evento.imagens.map((img, index) => (
            <div key={index} className="col-md-4 mb-3">
              <img
                src={getImageUrl(img)}
                alt={`Evento ${evento.titulo} - Imagem ${index + 1}`}
                className="img-fluid rounded"
                style={{
                  objectFit: 'cover',
                  height: '400px',
                  width: '100%',
                  cursor: 'pointer'
                }}
                onClick={() => openModal(getImageUrl(img))}
              />
            </div>
          ))
        ) : (
          <div className="col">
            <img
              src={`data:image/jpeg;base64,${evento.arquivoBase64}`}
              alt={`Evento ${evento.titulo}`}
              className="img-fluid rounded"
              style={{
                objectFit: 'cover',
                height: '400px',
                width: '100%',
                cursor: 'pointer'
              }}
              onClick={() => openModal(`data:image/jpeg;base64,${evento.arquivoBase64}`)}
            />
          </div>
        )}
      </div>

      {/* Modal para Visualização da Imagem */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Visualização da Imagem</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <img src={modalImage} alt="Visualização" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Botão de Voltar */}
      <div className="mt-4 text-center">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft}/>   Voltar
        </button>
      </div>
    </div>
  );
};

const getImageUrl = (image) => {
  if (image?.arquivoBase64) {
    return `data:image/jpeg;base64,${image.arquivoBase64}`;
  } else {
    return api.getUri() + image.url;
  }
};

export default Evento;
