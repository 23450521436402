import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCalendar, faRightToBracket, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import LogoImage from '../../resources/img/logo.jpg';

const Index = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center py-5">

            <div className="card text-center" style={{
                padding: "30px",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "white"
            }}>
                <img
                    src={LogoImage}
                    width="150"
                    height="150"
                    className="m-auto"
                    alt="Logo"
                />
                <h1 className="mb-4 text-primary text-danger">Crisma Raiz</h1>
                {/* <div id="carouselExample" className="carousel slide mb-4 py-5" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img width={400} src="https://modelosdeconvite.com.br/wp-content/uploads/2016/09/convites-para-aniversario-de-igrejas01.jpg" className="d-block" alt="Evento 1" />
                            <div className="carousel-caption d-none d-md-block">
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img width={400} src="https://i.pinimg.com/originals/19/8a/8b/198a8b908f52f7e4c3161c367e0a7988.png" className="d-block" alt="Evento 2" />
                            <div className="carousel-caption d-none d-md-block">
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img width={400} src="https://www.zapcatalao.com.br/wp-content/uploads/2018/09/IMG-20180824-WA0072-775x544.jpg" className="d-block" alt="Evento 3" />
                            <div className="carousel-caption d-none d-md-block">
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Anterior</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Próximo</span>
                    </button>
                </div> */}

                {/* Botões */}
                <div className="d-grid gap-3 w-100 mt-3 mx-auto" style={{ maxWidth: "300px" }}>
                    <a href="/cadastro" className="btn btn-warning" style={{ transition: "all 0.3s ease" }}>
                        <FontAwesomeIcon icon={faUserPlus} /> Incrição
                    </a>
                    
                    <a href="/login" className="btn btn-primary" style={{ transition: "all 0.3s ease" }}>
                        <FontAwesomeIcon icon={faRightToBracket} /> Login
                    </a>

                    <br />
                    <p className="text-muted p-0 m-0">Acompanhe nossos eventos e se conecte com a comunidade.</p>
                    <a href="/eventos" className="btn btn-outline-primary" style={{ transition: "all 0.3s ease" }}>
                        <FontAwesomeIcon icon={faCalendar} /> Eventos
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Index;
