import React, { useEffect, useState } from 'react';
import { eventoService } from '../../services/eventoService';
import LogoImage from '../../resources/img/logo.jpg';
import { faArrowLeft, faArrowRight, faArrowsLeftRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api/api';

const Eventos = () => {
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const data = await eventoService.getAcontecidos();
        // Considerando que os eventos estão em data.itens
        setEventos(data.itens);
      } catch (error) {
        console.error("Erro ao buscar eventos em breve:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventos();
  }, []);

  return (
    <div className="container">
      {/* Cabeçalho com logo e título */}
      <div className="row mb-4">
        <div className="col-12 text-center">
          <img
            src={LogoImage}
            width="80"
            height="80"
            className="mb-3"
            alt="Logo"
          />
          <h1 className="text-danger">Crisma Raiz - Eventos</h1>
          <p className="text-muted">Confira nossos eventos e participe!</p>
        </div>
      </div>

      {/* Exibe spinner enquanto carrega */}
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
      ) : (
        <>
          <div className='row'>
            {eventos.length > 0 ? (
              eventos.map((evento) => (
                <div className='col-12 col-md-6'>
                  <EventoCarousel key={evento.id || evento.titulo} evento={evento} />
                </div>
              ))
            ) : (
              <div className="col-12 my-5 text-center text-muted">
                <h2>
                  No momento, não há eventos disponíveis para exibição. Fique de olho nesta página para se manter atualizado sobre os eventos da nossa comunidade!
                </h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const EventoCarousel = ({ evento }) => {
  // Gera um ID único para cada carousel
  const carouselId = `carousel-${evento.id || evento.titulo.replace(/\s/g, '-')}`;

  return (
    <div className="card mb-4 shadow-sm border-1">
      <div className="card-header">
        <h5 className="mb-0 d-flex justify-content-between">
          {evento.titulo}
          <a className='btn btn-sm btn-primary' href={`/evento/${evento.id}`}>Detalhes <FontAwesomeIcon icon={faArrowRight}/></a>
        </h5>
      </div>
      <div className="card-body p-0">
        <div id={carouselId} className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {evento.imagens && evento.imagens.length > 0 && (
              evento.imagens.map((img, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                >
                  <img
                    src={getImageUrl(img)}
                    className="d-block w-100"
                    alt={`Evento ${evento.titulo} - Imagem ${index + 1}`}
                    style={{ objectFit: 'cover', height: '300px' }}
                  />
                </div>
              ))
            )}
          </div>
          {/* Botões de navegação do carousel */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={`#${carouselId}`}
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Anterior</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={`#${carouselId}`}
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Próximo</span>
          </button>
        </div>
        {/* Informações do evento (data, por exemplo) */}
        <div className="p-3">
          <p className="text-muted m-0 fw-bold">
            <FontAwesomeIcon icon={faCalendar} />
            <small className="ms-2">
              {new Date(evento.data).toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

const getImageUrl = (image) => {
  if (image?.arquivoBase64) {
    return `data:image/jpeg;base64,${image.arquivoBase64}`;
  } else {
    return api.getUri() + image.url;
  }
};

export default Eventos;
