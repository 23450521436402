import axios from "axios";

const hostname = window.location.hostname;

let baseUrl = "http://localhost:5131/api";

if (hostname.includes("danielsanchesdev")) {
  baseUrl = "https://crismaraiz-api.danielsanchesdev.com.br/api";
} else if (hostname.includes("crismaraiz.com.br")) {
  baseUrl = "https://crismaraiz-api.crismaraiz.com.br/api";
}

const getUsuarioLocal = () => {
  const usuarioJSON = localStorage.getItem("user");
  return usuarioJSON ? JSON.parse(usuarioJSON) : null;
};

const adicionarTokenAoCabecalho = () => {
  const usuario = getUsuarioLocal();

  if (usuario && usuario?.token) {
    return {
      Authorization: `Bearer ${usuario.token}`
    };
  }

  return {};
};
const api = axios.create({
  baseURL: baseUrl,
  headers: adicionarTokenAoCabecalho()
});

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...adicionarTokenAoCabecalho()
  };

  return config;
});

export default api;