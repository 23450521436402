import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { catequistaService } from './../../../services/catequistaService';
import { comunidadeService } from '../../../services/comunidadeService';
import InputMask from 'react-input-mask';
import { isValidPhoneNumber } from '../../../Util/Validadores';
import { toast } from 'react-toastify';
import { formatarDataParaInput } from '../../../Util/DataUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';

function PageCadastroCatequista() {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        login: '',
        dataNascimento: '',
        telefone: '',
        dataInicioAtividade: '',
        dataTerminoAtividade: '',
        financeiro: false,
        senha: '',
        confirmarSenha: '',
        comunidadeId: 0,
        userId: 0
    });
    const [telefoneValido, setTelefoneValido] = useState(false);
    const [comunidades, setComunidades] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await comunidadeService.get();
                setComunidades(data?.itens);

                if (id) {
                    const catequista = await catequistaService.getId(id);
                    if (catequista) {
                        setFormData({
                            ...catequista,
                            dataInicioAtividade: formatarDataParaInput(catequista.dataInicioAtividade),
                            dataTerminoAtividade: (catequista.dataTerminoAtividade?.length ?? 0 == 0) ? '' : formatarDataParaInput(catequista.dataTerminoAtividade),
                            dataNascimento: formatarDataParaInput(catequista.dataNascimento),
                            login: catequista?.user?.userName,
                            financeiro: catequista?.financeiro ?? false,
                            userId: catequista.userId
                        });
                    }
                }
            } catch (error) {
                console.error('Erro ao obter comunidades:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (event) => {
        const { id, value, type, checked } = event.target;

        setFormData({
            ...formData,
            [id]: type === "checkbox" ? checked : value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        try {
            if (form.checkValidity() === true) {
                const { dataNascimento, dataInicioAtividade, dataTerminoAtividade } = formData;
                const currentDate = new Date().toISOString().split('T')[0];

                if (dataNascimento > currentDate || dataInicioAtividade > currentDate || dataTerminoAtividade > currentDate) {
                    return;
                }

                if (formData.senha !== formData.confirmarSenha) {
                    toast.error("As senhas não conferem");
                    return;
                }

                const catequistaData = {
                    id: id ?? 0,
                    nome: formData.nome,
                    dataNascimento: new Date(formData.dataNascimento).toJSON(),
                    telefone: formData.telefone,
                    dataInicioAtividade: new Date(formData.dataInicioAtividade).toJSON(),
                    dataTerminoAtividade: (formData?.dataTerminoAtividade ?? '')?.length == 0 ? null : new Date(formData.dataTerminoAtividade).toJSON(),
                    comunidadeId: Number(formData.comunidadeId),
                    financeiro: formData?.financeiro ?? false,
                    userId: formData?.userId ?? 0,
                    // role: null,
                    comunidade: null,
                    user: {
                        userName: formData.login,
                        token: null,
                        senha: formData.senha,
                        role: null
                    }
                };

                delete catequistaData.confirmarSenha;

                catequistaService.post(catequistaData).then(resp => {
                    if (resp && (id ?? 0) <= 0) {
                        setFormData({
                            nome: '',
                            login: '',
                            dataNascimento: '',
                            telefone: '',
                            dataInicioAtividade: '',
                            dataTerminoAtividade: '',
                            financeiro: false,
                            senha: '',
                            confirmarSenha: '',
                            comunidadeId: 0
                        });
                    }
                    setValidated(false);
                    
                    if (id) {
                        toast.success("Catequista atualizado com sucesso!");
                    } else {
                        toast.success("Catequista cadastrado com sucesso!");
                    }
                    
                }).catch(error => {
                    console.error('Erro ao cadastrar catequista:', error);
                })
            }
        } catch (error) {
            console.error('Erro ao cadastrar catequista:', error);
        }
    };

    return (
        <Row className='d-flex justify-content-center'>
            <Col md={'10'}>
                {id > 0 && (
                    <Alert variant='warning' className='text-center'>Para alterar a senha, basta informar a nova senha no campo.</Alert>
                )}

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="nome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Nome do catequista"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, insira o nome do catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="dataNascimento">
                            <Form.Label>Data de Nascimento</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                value={formData.dataNascimento}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, insira a data de nascimento do catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="telefone">
                            <Form.Label>Telefone</Form.Label>
                            <InputMask
                                mask="(99) 99999-9999"
                                required
                                value={formData.telefone}
                                onChange={handleChange}
                            >
                                {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="(99) 99999-9999" />}
                            </InputMask>
                            {!telefoneValido && <Form.Control.Feedback type="invalid">
                                Por favor, insira um número de telefone válido.
                            </Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="dataInicioAtividade">
                            <Form.Label>Data de Início da Atividade</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                value={formData.dataInicioAtividade}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, insira a data de início da atividade do catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="dataTerminoAtividade">
                            <Form.Label>Data de Término da Atividade
                                {(formData?.dataTerminoAtividade?.length ?? 0) > 0 && (
                                    <Button onClick={() => setFormData({ ...formData, dataTerminoAtividade: '' })} className='ms-2' size='sm' variant='danger'>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </Button>
                                )}
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={(formData?.dataTerminoAtividade ?? '')?.length == 0 ? '' : formData.dataTerminoAtividade}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, insira uma data posterior à data de início da atividade para o término da atividade do catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="comunidadeId">
                            <Form.Label>Comunidade</Form.Label>
                            <Form.Select
                                required
                                as="select"
                                value={formData.comunidadeId}
                                onChange={handleChange}
                            >
                                <option value="">Selecione uma comunidade</option>
                                {comunidades?.map(comunidade => (
                                    <option key={comunidade.id} value={comunidade.id}>{comunidade.nome}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Por favor, selecione uma comunidade para o catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='d-flex align-items-center'>
                            <div className="border rounded-3">
                                <div className="ms-5 form-check form-switch p-3">
                                    <input
                                        id="financeiro"
                                        checked={formData.financeiro}
                                        onChange={handleChange}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                    />
                                    <label className="form-check-label" htmlFor="financeiro">
                                        Permitir movimentação financeira
                                    </label>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="login">
                            <Form.Label>Login</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Login do catequista"
                                value={formData.login}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, insira o login do catequista.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="senha">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                required={id == 0}
                                type="password"
                                placeholder="Senha"
                                minLength="6"
                                value={formData.senha}
                                onChange={handleChange}
                            />
                            {id == 0 && (
                                <Form.Control.Feedback type="invalid">
                                    A senha deve ter no mínimo 6 caracteres.
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} className='mb-2' xs={12} md={6} controlId="confirmarSenha">
                            <Form.Label>Confirmar Senha</Form.Label>
                            <Form.Control
                                required={id == 0}
                                type="password"
                                placeholder="Confirmar Senha"
                                minLength="6"
                                value={formData.confirmarSenha}
                                onChange={handleChange}
                            />
                            {id == 0 && (<Form.Control.Feedback type="invalid">
                                As senhas devem coincidir.
                            </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Button type="submit">Salvar</Button>
                </Form>
            </Col>
        </Row>
    );
}

export default PageCadastroCatequista;
