import React, { useEffect, useState } from "react";
import { Table, Button, Form, Row, Col, Container, Pagination, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { eventoService } from "../../../services/eventoService";
import { useNavigate } from "react-router-dom";
import { formatarData } from "../../../Util/DataUtil";

const ListagemEventos = () => {
  const [pageListEventos, setPageListEventos] = useState({});
  const [pageParams, setPageParams] = useState({
    termoBusca: "",
    numeroPagina: 1,
    dataInicial: new Date().toISOString().split("T")[0], // Data de hoje
    dataFinal: new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().split("T")[0], // 3 meses à frente
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEventos();
  }, [pageParams]);

  const fetchEventos = async () => {
    setLoading(true);
    try {
      const response = await eventoService.get(pageParams);
      setPageListEventos(response);
    } catch (error) {
      toast.error("Erro ao carregar eventos");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setPageParams({ ...pageParams, termoBusca: event.target.value, numeroPagina: 1 });
  };

  const handleDateChange = (event) => {
    setPageParams({ ...pageParams, [event.target.name]: event.target.value, numeroPagina: 1 });
  };

  const handlePaginationClick = (page) => {
    if (page < 1 || page > (pageListEventos?.totalPaginas ?? 0)) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const removerEvento = async (id) => {
    if (!window.confirm("Tem certeza que deseja remover este evento?")) {
      return;
    }
    try {
      await eventoService.deleteData(id);
      toast.success("Evento removido com sucesso!");
      fetchEventos();
    } catch (error) {
      toast.error("Erro ao remover evento");
    }
  };

  const editarEvento = (id) => {
    navigate(`/admin/eventos/editar/${id}`);
  };

  const novoEvento = () => {
    navigate(`/admin/eventos/novo`);
  };

  const renderPaginationItems = () => {
    const totalPages = pageListEventos?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Container className={loading ? "vh-100" : ""}>
      <Row className="align-items-center mb-3">
        <Col lg={8} className="text-end mx-auto">
          <Button variant="primary" onClick={novoEvento}>
            <FontAwesomeIcon icon={faPlus} /> Novo Evento
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={8} className="mx-auto">
          <h2>Eventos</h2>
          <Form>
            <Form.Group>
              <Form.Label>Buscar por título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Qual evento deseja encontrar?"
                value={pageParams.termoBusca}
                onChange={handleSearchChange}
              />
            </Form.Group>

            {/* 🗓️ Campos de Data Inicial e Final para Filtragem */}
            <Row className="mt-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Data Inicial</Form.Label>
                  <Form.Control
                    type="date"
                    name="dataInicial"
                    value={pageParams.dataInicial}
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Data Final</Form.Label>
                  <Form.Control
                    type="date"
                    name="dataFinal"
                    value={pageParams.dataFinal}
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <small className="d-block text-muted text-end">
        Exibindo <strong>{pageListEventos?.itens?.length ?? 0}</strong> de um total de <strong>{pageListEventos?.qtdResultados ?? 0}</strong> resultados
      </small>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center p-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Row className="mt-3">
            <Col lg={8} className="mx-auto">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Título do evento</th>
                    <th>Data exibição</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {pageListEventos?.itens?.map((evento, index) => (
                    <tr key={index} onClick={() => editarEvento(evento.id)}>
                      <td>{evento.titulo}</td>
                      <td>{formatarData(evento.dataExibicaoInicio) + ' - ' + (evento.dataExibicaoFim == null ? 'Sem fim' : formatarData(evento.dataExibicaoFim))}</td>
                      <td>
                        <Button
                          className="me-2"
                          variant="danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            removerEvento(evento.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            editarEvento(evento.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          {pageListEventos?.itens?.length === 0 && (
            <h5 className="d-flex justify-content-center">Nenhum resultado encontrado</h5>
          )}

          <Row>
            <Col>
              <Pagination className="d-flex justify-content-center pagination-sm">
                <Pagination.First onClick={() => handlePaginationClick(1)} />
                <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
                {renderPaginationItems()}
                <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
                <Pagination.Last onClick={() => handlePaginationClick(pageListEventos?.totalPaginas ?? 0)} />
              </Pagination>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ListagemEventos;
