import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { eventoService } from "../../../services/eventoService";
import { formatarDataParaInput } from "../../../Util/DataUtil";

const CadastroEvento = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();

    const [previews, setPreviews] = useState([]);
    const [base64Files, setBase64Files] = useState([]);
    const [validated, setValidated] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [fileError, setFileError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id && id !== "0") {
            buscarEvento();
        } else {
            definirDatasPadrao();
            setLoading(false);
        }
    }, [id]);

    const buscarEvento = async () => {
        try {
            const evento = await eventoService.getId(id);
            if (evento) {
                setValue("titulo", evento.titulo);
                setValue("data", evento.data ? formatarDataParaInput(evento.data) : "");
                setValue("dataExibicaoInicio", formatarDataParaInput(evento.dataExibicaoInicio));
                setValue("dataExibicaoFim", evento.dataExibicaoFim ? formatarDataParaInput(evento.dataExibicaoFim) : "");
                setValue("exibirPermanentemente", !evento.dataExibicaoFim);

                // Load existing images from the API
                if (evento.imagens && evento.imagens.length > 0) {
                    const loadedPreviews = evento.imagens.map(img => getImageUrl(img));
                    setPreviews(loadedPreviews);
                    setBase64Files(loadedPreviews);
                }
            }
        } catch (error) {
            toast.error("Erro ao carregar evento.");
        } finally {
            setLoading(false);
        }
    };

    const getImageUrl = (image) => {
        if (image.arquivoBase64) {
            return `data:image/jpeg;base64,${image.arquivoBase64}`;
        } else {
            return image.url;
        }
    }

    const definirDatasPadrao = () => {
        const hoje = new Date();
        const dataInicioPadrao = formatarDataParaInput(hoje);

        const dataFimPadrao = new Date();
        dataFimPadrao.setDate(dataFimPadrao.getDate() + 30);

        setValue("dataExibicaoInicio", dataInicioPadrao);
        setValue("dataExibicaoFim", formatarDataParaInput(dataFimPadrao));
        setValue("exibirPermanentemente", false);
        setValue("data", dataInicioPadrao);
    };

    const onSubmit = async (data) => {
        setValidated(true);
        setRequesting(true);

        if (base64Files.length === 0) {
            setFileError("Pelo menos uma imagem é obrigatória.");
            setRequesting(false);
            return;
        }

        var imagens = [];

        base64Files.forEach((base64) => {
            imagens.push({
                eventoId: id ? Number(id) : 0,
                arquivoBase64: base64
            });
        });

        const eventoData = {
            id: id ? Number(id) : 0,
            titulo: data.titulo,
            data: formatarDataParaInput(data.data),
            imagens, // Send all base64 images here
            dataExibicaoInicio: formatarDataParaInput(data.dataExibicaoInicio),
            dataExibicaoFim: data.exibirPermanentemente ? null : formatarDataParaInput(data.dataExibicaoFim),
        };

        try {
            await eventoService.post(eventoData);

            if (id && id !== "0") {
                toast.success("Evento atualizado com sucesso!");
            } else {
                toast.success("Evento cadastrado com sucesso!");
            }
            navigate("/admin/eventos");
        } catch (error) {
            toast.error("Erro ao salvar evento");
        } finally {
            setRequesting(false);
        }
    };

    const handleFileChange = (index, e) => {
        const files = e.target.files;
        const newPreviews = [...previews];
        const newBase64Files = [...base64Files];

        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                newPreviews[index] = URL.createObjectURL(files[0]);
                newBase64Files[index] = reader.result;

                setPreviews(newPreviews);
                setBase64Files(newBase64Files);
                setFileError("");
            };
        }
    };

    const handleAddImage = () => {
        if(naoPodeAdicionarImagem()) {
            toast.warning("Preencha todos os campos de imagem antes de adicionar mais.");
            return;
        }

        setPreviews([...previews, ""]);
        setBase64Files([...base64Files, ""]);
    };

    const naoPodeAdicionarImagem = () => {
        const hasEmptyPreview = previews.some((preview) => preview?.length === 0) && previews?.length > 0;
        return hasEmptyPreview;
    }

    const handleRemoveImage = (index) => {
        const confirmDelete = window.confirm("Tem certeza que deseja excluir esta imagem?");
        if (confirmDelete) {
            const updatedPreviews = previews.filter((_, i) => i !== index);
            const updatedBase64Files = base64Files.filter((_, i) => i !== index);
            setPreviews(updatedPreviews);
            setBase64Files(updatedBase64Files);
        }
    };

    const exibirPermanentemente = watch("exibirPermanentemente");
    const dataFim = watch("dataExibicaoFim");

    useEffect(() => {
        if (exibirPermanentemente) {
            setValue("dataExibicaoFim", "");
            clearErrors("dataExibicaoFim");
        } else if (!dataFim) {
            const dataFimPadrao = new Date();
            dataFimPadrao.setDate(dataFimPadrao.getDate() + 30);
            setValue("dataExibicaoFim", formatarDataParaInput(dataFimPadrao));
        }
    }, [exibirPermanentemente, setValue, dataFim, clearErrors]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 col-md-10 mx-auto">
                <div className="mt-4 p-1 p-md-4">
                    <h2 className="text-center">{id && id !== "0" ? "Editar Evento" : "Cadastro de Evento"}</h2>

                    <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Título do Evento <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                placeholder="Informe o título do evento"
                                {...register("titulo", { required: "O título é obrigatório." })}
                                isInvalid={!!errors.titulo}
                            />
                            <Form.Control.Feedback type="invalid">{errors.titulo?.message}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Data do Evento <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="date"
                                {...register("data", { required: "A data do evento é obrigatória." })}
                                isInvalid={!!errors.data}
                            />
                            <Form.Control.Feedback type="invalid">{errors.data?.message}</Form.Control.Feedback>
                        </Form.Group>

                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Imagens <span className="text-danger me-2">*</span></Form.Label>
                                    <Button variant="secondary" onClick={handleAddImage} className="mb-2">
                                        Adicionar Imagem
                                    </Button>
                                    <Form.Control.Feedback type="invalid">{fileError}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <div className="row d-flex justify-between ">
                                    {previews.map((preview, index) => (
                                        <div key={index} className="col-6 col-md-4 position-relative">
                                            <div className="border p-2 rounded-3 mb-2">
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(index, e)}
                                                    className="my-2"
                                                />
                                                <img
                                                    src={preview || ""}
                                                    style={{ width: '100%' }}
                                                    alt={`Preview ${index}`}
                                                    className="p-2 mx w-32 h-32 object-cover rounded"
                                                />
                                                <Button
                                                    variant="danger"
                                                    className="position-absolute top-0 end-0"
                                                    onClick={() => handleRemoveImage(index)}>X
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Data de Início <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="date" {...register("dataExibicaoInicio")} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="dataExibicaoFim">Data de Fim</Form.Label>
                                    <Form.Control id="dataExibicaoFim" type="date" {...register("dataExibicaoFim")} disabled={exibirPermanentemente} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="exibirPermanentemente"
                                {...register("exibirPermanentemente")}
                                label={
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setValue("exibirPermanentemente", !exibirPermanentemente)}
                                    >
                                        Exibir permanentemente
                                    </span>
                                }
                            />
                        </Form.Group>

                        <Button type="submit" variant="primary" disabled={requesting} className="w-100">
                            {requesting ? "Salvando..." : "Salvar"}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CadastroEvento;
